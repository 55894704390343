import React from 'react';

function Home() {
  return (
    <div className="doc">
      <h1>This is the technical documentation test page.</h1>
      <p>Built on Algorand</p>
      <h2>Welcome to BLOCKCYCLE</h2>
      <p>
        A one stop platform for NFTs and Token Staking. Earn $CYCLE and many more rewards.
      </p>
      <button className="launch-button">Launch CYCLE</button>
    </div>
  );
}

export default Home;