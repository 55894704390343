import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Stake from './components/Stake';
import Doc from './components/Doc';
import BuyCycle from './components/BuyCycle';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className="App">
        {/* Navbar */}
        <nav className="navbar">
          <div className="nav-container">
            <div className="logo">
              <Link to="/"><img className="logo-img" src="logo.png" alt="Logo Icon"></img></Link>
            </div>

            {/* Hamburger menu button */}
            <div className="menu-icon" onClick={toggleMenu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>

            {/* Navigation Links - Show/Hide based on menuOpen state */}
            <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
              <li><Link to="/stake" onClick={closeMenu}>Stake</Link></li>
              <li><Link to="/doc" onClick={closeMenu}>Doc</Link></li>
              <li><Link to="/buy-cycle" onClick={closeMenu}>Buy $Cycle</Link></li>
              <li>
                <a href="https://app.blockcycle.org" target="_blank" rel="noopener noreferrer">
                  <button className="launch-button">Launch CYCLE</button>
                </a>
              </li>
            </ul>
          </div>
        </nav>

        {/* Content Routes */}
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/doc" element={<Doc />} />
            <Route path="/buy-cycle" element={<BuyCycle />} />
            <Route path="/stake" element={<Stake />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
