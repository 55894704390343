import React from 'react';

function Home() {
  return (
    <div className="home">
      <div className="left-column">
        <h1 className="big-home-text">The Future of Staking is here.</h1>
        <p style={{textAlign: 'right', marginTop: "0px"}}>Built on Algorand</p>
        <h2 style={{marginTop: "65px"}}>Welcome to BLOCKCYCLE</h2>
        <p>
          Your 1st stop in Decentralized Finance to learn and earn $CYCLE and many more rewards.
        </p>
      </div>
      <div className="right-column">
        <img className="vault" alt="vault icon" src="vault.png"></img>
      </div>
    </div>
  );
}

export default Home;